import React, { Component } from "react";
import "./Input.scss";


class Input extends Component {
	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		this.setState({value: e.target.value},() => {
			if (this.props.return) {
				this.props.return(this.props.placeholder,this.state.value);
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.clear && this.props.clear && this.state.value !== '') {
			this.setState({value: ''},() => {
				this.props.return(this.props.placeholder,this.state.value);
			})
		}
	}

	render() {
		return (
			<input
				className="input"
				type={this.props.type || 'text'}
				placeholder={this.props.placeholder || ""}
				autoComplete={this.props.autocomplete || "off"}
				value={this.state.value}
				required={this.props.required || false}
				onChange={(e) => this.handleChange(e)}
			/>
		)
	}
}

export default Input;