import React, { Component } from "react";
import "./ContactUsForm.scss";
import Input from "../Input";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import Button from "../Button";
import axios from "axios";
import FormSuccess from "./FormSuccess/FormSuccess";
import FormError from "./FormError/FormError";
import { STAGES, EMAIL_REGEX } from "./constants";
import PopUp from "../PopUp";
import * as Yup from "yup";


const API_URL = /*process.env.REACT_APP_HUBSPOT_API_URL*/"https://hubspot-svc.prod.aws.beatbread.com";

class ContactUsForm extends Component {
	constructor() {
		super();
		this.state = {
			yourName: null,
			emailAddress: null,
			companyName: null,
			companyWebsite: null,
			//yourRole: '',
			//phoneNumber: '',
			companyType: '',
			numberOfArtistsYouServe: '',
			yourMessage: null,
			buttonDisabled: true,
			clear: false,
		};
	}

	resetState = () => {
		this.setState({
			yourName: null,
			emailAddress: null,
			companyName: null,
			companyWebsite: null,
			//yourRole: '',
			//phoneNumber: '',
			companyType: '',
			numberOfArtistsYouServe: '',
			yourMessage: null,
			buttonDisabled: true,
			clear: false,
		});
	}

	enableButton = () => {
		if (this.state.yourName && this.state.emailAddress && this.state.companyName && this.state.companyWebsite && this.state.yourMessage) {
			this.setState({buttonDisabled: false});
		} else {
			this.setState({buttonDisabled: true});
		}
	}

	handleSubmit = () => {
		const payload = {
			name: this.state.yourName,
			email: this.state.emailAddress,
			//phone: this.state.phoneNumber,
			company_name: this.state.companyName,
			company_website: this.state.companyWebsite,
			//role: this.state.yourRole,
			company_type: this.state.companyType,
			'#_of_artists_served': this.state.numberOfArtistsYouServe,
			your_message: this.state.yourMessage,
			filled_out_chordcash_investor_form_: true,
		};
		this.setState({ loader: true });
		axios.post(`${API_URL}/hubspot/chordcash_investors/`,payload)
		.then((res) => {
			this.setState({ loader: false });
			this.setState({ stage: "success" });
		  })
		  .catch((err) => {
			this.setState({ loader: false });
			this.setState({ stage: "error" });
		  })
		  .finally(() => {
			this.resetState();
		  });
	}

	handleReturn = (key,value) => {
		const cleanKey = (inKey) => {
			let cleanedKey = inKey.replace(/\*/gmi,"").replace(/\(Optional\)/gmi,"");
			cleanedKey = cleanedKey.split(' ').map((e) => `${e.slice(0,1).toUpperCase()}${e.slice(1).toLowerCase()}`).join('');
			cleanedKey = `${cleanedKey.slice(0,1).toLowerCase()}${cleanedKey.slice(1)}`;
			return cleanedKey;
		}
		this.setState({[cleanKey(key)]:value},() => {
			this.enableButton();
		});
	}

	renderInitial = () =>
    this.setState({
      stage: STAGES.INITIAL,
    });

	triggerAlert = (alertText, delay = 5000) => {
		this.setState(
			{
			showAlert: true,
			...(alertText ? { alertText: alertText } : {}),
			},
			() => {
			setTimeout(
				() =>
				this.setState({ showAlert: false }, () => {
					if (alertText) {
					setTimeout(() => {
						this.setState({ alertText: "" });
					}, delay);
					}
				}),
				delay
			);
			}
		);
	};
	emailValidation = () => EMAIL_REGEX;

	changeComponentErr = () => {
		return <FormError onClick={this.renderInitial} />;
	};
	
	changeComponentSuc = () => {
		return <FormSuccess />;
	};

	renderWidgetForm = () => (
		<div className="contact-us-form">
			<PopUp logo open={this.state.showAlert}>
				{this.state.alertText}
			</PopUp>
			<div className="cuf-title">Learn More</div>
			<div className="cuf-inputs">
				<Input
					placeholder="Your Name*"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="name"
					label="Name"
					required
				/>
				<Input
					placeholder="Email Address*"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="email"
					label="Email"
					required
				/>
				<Input
					placeholder="Company Name*"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="organization"
					label="Company Name"
					required
				/>
				<Input
					placeholder="Company Website*"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="url"
					label="Website"
					required
				/>
				{/* 
				<Input
					placeholder="Your Role (Optional)"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="organization-title"
				/>
				<Input
					placeholder="Phone Number (Optional)"
					clear={this.state.clear}
					return={this.handleReturn}
					autocomplete="tel"
				/> 
				*/}
				<Dropdown
					placeholder="Select Type"
					title="Type of Company"
					options={[
						'Independent Label',
						'Distributor',
						'Artist Services Company',
						'Other',
					]}
					clear={this.state.clear}
					return={this.handleReturn}
					includeTitle
				/>
				<Dropdown
					placeholder="Select Range"
					title="Company Size"
					options={[
						'1-9 Employees',
						'10-49 Employees',
						'50-99 Employees',
						'More than 100 Employees',
					]}
					clear={this.state.clear}
					return={this.handleReturn}
					includeTitle
				/>
				<TextArea
					placeholder="Your Message*"
					title="Add a Message"
					clear={this.state.clear}
					return={this.handleReturn}
					label="Message"
					required
					includeTitle
					/>
			</div>
			<Button
				onClick={this.handleValid}
			>
				SEND
			</Button>
		</div>
	);

	validationSchema = Yup.object().shape({
		name: Yup.string().label("Name").required(),
		company_name: Yup.string().label("Company Name").required(),
		company_website: Yup.string().label("Website").required(),
		email: Yup.string()
		  .label("Email")
		  .required()
		  .matches(EMAIL_REGEX, "Please enter valid email"),
		your_message: Yup.string().label("Message").required(),
	  });
	
	  handleValid = async () => {
		const values = {
		  name: this.state.yourName,
		  company_name: this.state.companyName,
		  company_website: this.state.companyWebsite,
		  email: this.state.emailAddress,
		  your_message: this.state.yourMessage,
		};
		const validate = await this.validationSchema.isValid(values);
		if (!validate) {
		  this.validationSchema
			.validate(values, { abortEarly: false })
			.catch((err) => {
			  const errorMsg = err.inner.length > 0 && err.inner[0].message;
			  this.triggerAlert(errorMsg);
			});
		} else {
		  this.handleSubmit();
		}
	  };

	render() {
		switch (this.state.stage) {
		  case STAGES.INITIAL:
			return this.renderWidgetForm();
	
		  case STAGES.SUCCESS:
			return this.changeComponentSuc();
	
		  case STAGES.ERROR:
			return this.changeComponentErr();
	
		  default:
			return this.renderWidgetForm();
		}
	  }
	
}

export default ContactUsForm;