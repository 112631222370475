import React, { Component } from "react";
import "./Dropdown.scss";

class Dropdown extends Component {
	constructor() {
		super();
		this.state = {
			value: '',
		}
	}


	handleDropdownChange = (e) => {
		this.setState({value: e.target.value},() => {
			if (this.props.return) {
				this.props.return(this.props.placeholder,this.state.value);
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.clear && this.props.clear && this.state.value !== '') {
			this.setState({value: ''},() => {
				this.props.return(this.props.placeholder,this.state.value);
			});
		}
	}

	render() {
		return (
			<div className="dropdown">
				<p style={{display:this.props.includeTitle}}>{this.props.title || ""}</p>
				<select className="dropdown-container" value={this.state.value} onChange={(e) => this.handleDropdownChange(e)}>
				{
					this.props.placeholder ?
					<option value={this.props.placeholder} disabled>{this.props.placeholder}</option>
					:
					<></>
				}
				{
					this.props.options ?
					this.props.options.map((e,i) => <option key={i} value={e}>{e}</option>)
					:
					<></>
				}
				</select>
			</div>
		)
	}
}

export default Dropdown;